// @ts-nocheck

import React from 'react';
import { useIntl, injectIntl } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import 'animate.css/animate.min.css';
import { Row, Col } from 'react-bootstrap';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Title from '../components/Title';
import heroImage from '../images/about-us-background.jpg';
import Footer from '../components/Footer';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const H1 = styled.h1`
  color: #004165;
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
`;

const ImportantText = styled.p`
  font-size: 1.03em;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
`;

const ImportantTextContainer = styled.div`
  background: #004165;
  text-align: center;
  padding: 1.5em 3em;
  @media (min-width: 992px) {
    text-align: left;
    width: 100%;
    margin: auto;
  }
  & p:last-child {
    margin-bottom: 0px;
  }
`;

const ParagraphContainer = styled(Row)`
  padding: 1.5em;
  & li {
    padding-bottom: 0.5em;
  }
`;

const AbsoluteImage = styled.img`
  display: none;
  @media (min-width: 1500px) {
    display: block;
    position: absolute;
    left: 57%;
    width: 18em;
    top: 25%;
  }

  @media (min-width: 1700px) {
    width: 21em;
    top: 22%;
  }
`;

const FooterContainer = styled(Col)`
  background: #004165;
  text-align: center;
  padding: 1.5em;
  & p {
    color: white;
    margin-bottom: 0em;
    font-style: italic;
  }
`;

const AboutPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title="About Us" />
      <Wrapper className="container-fluid">
        <Title
          imageUrl={heroImage}
          position="top"
          text={intl.formatMessage({ id: 'about.hero_text' })}
        />
        <Row>
          <Col
            lg={8}
            md={10}
            sm={12}
            className="mx-auto pt-5"
            style={{ padding: '0px' }}
          >
            <AbsoluteImage />
            <H1>{intl.formatMessage({ id: 'about.title' })}</H1>
            <ImportantTextContainer>
              <ImportantText>
                {intl.formatMessage({ id: 'about.important_text_1' })}
              </ImportantText>
              <ImportantText>
                {intl.formatMessage({ id: 'about.important_text_2' })}
              </ImportantText>
              <ImportantText>
                {intl.formatMessage({ id: 'about.important_text_3' })}
              </ImportantText>
            </ImportantTextContainer>
            <ParagraphContainer>
              <Col sm={12}>
                <p>
                  <strong>
                    {intl.formatMessage({ id: 'about.paragraph_1.title' })}
                  </strong>
                </p>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_1.point_1',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_1.point_2',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_1.point_3',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_1.point_4',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_1.point_5',
                    })}
                  </li>
                </ul>
                <p>
                  <strong>
                    {intl.formatMessage({ id: 'about.paragraph_2.title' })}
                  </strong>
                </p>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_2.point_1',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_2.point_2',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_2.point_3',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_2.point_4',
                    })}
                  </li>
                </ul>
                <p>
                  <strong>
                    {intl.formatMessage({ id: 'about.paragraph_3.title' })}
                  </strong>
                </p>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_3.point_1',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_3.point_2',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_3.point_3',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_3.point_4',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_3.point_5',
                    })}
                  </li>

                </ul>
                <p>
                  <strong>
                    {intl.formatMessage({ id: 'about.paragraph_4.title' })}
                  </strong>
                </p>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_4.point_1',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_4.point_2',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_4.point_3',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'about.paragraph_4.point_4',
                    })}
                  </li>
                </ul>
              </Col>
            </ParagraphContainer>
          </Col>
        </Row>
        <Row>
          <FooterContainer className="col-lg-8 col-md-10 col-sm-12 text-center mx-auto pt-3">
            <p>{intl.formatMessage({ id: 'about.footer_text' })}</p>
          </FooterContainer>
        </Row>
        <Footer />
      </Wrapper>
    </Layout>
  );
};

export default injectIntl(AboutPage);
